import {OutboundLink} from 'gatsby-plugin-gtag-outbound';
import styled from 'styled-components';
import {Link} from 'rebass/styled-components';

export const Icon = styled(OutboundLink)`
  transition: color 0.5s;
  color: ${(props) =>
    props.theme.colors[props.color] || props.theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${(props) =>
      props.theme.colors[props.hovercolor] || props.theme.colors.primaryLight};
  }
`;

export const IconInternal = styled(Link)`
  transition: color 0.5s;
  color: ${(props) =>
    props.theme.colors[props.color] || props.theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${(props) =>
      props.theme.colors[props.hovercolor] || props.theme.colors.primaryLight};
  }
`;
