import React from 'react';
import Tippy from '@tippy.js/react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from 'react-fontawesome';
import 'tippy.js/dist/tippy.css'; // eslint-disable-line

import {Icon} from './styled';

const IconLink = ({fontAwesomeIcon, name, url, color, hoverColor}) => (
  <Tippy
    content={name}
    placement="bottom"
    trigger="mouseenter"
    arrow={false}
    touch={false}>
    <div>
      <Icon
        onClick={(e) => e.stopPropagation()}
        href={url}
        target="_blank"
        color={color}
        hovercolor={hoverColor}
        rel="noreferrer"
        aria-label={name}>
        <FontAwesomeIcon name={fontAwesomeIcon} />
      </Icon>
    </div>
  </Tippy>
);

IconLink.propTypes = {
  fontAwesomeIcon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
};

export default IconLink;
