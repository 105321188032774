import React from 'react';
import {SectionLink} from 'react-scroll-section';
import PropTypes from 'prop-types';
import {Heading} from 'rebass/styled-components';

import Section from '../Section';

import {
  StyledLink,
  MarkdownList,
  MarkdownListItem,
  MarkdownParagraph,
} from './styled';

const MarkdownLink = ({href, children}) => {
  const isInnerLink = href.startsWith('#');
  return isInnerLink ? (
    <SectionLink section={href.substring(1, href.length)}>
      {({onClick}) => <StyledLink onClick={onClick}>{children}</StyledLink>}
    </SectionLink>
  ) : (
    <StyledLink href={href} target="_blank" rel="noreferrer">
      {children}
    </StyledLink>
  );
};

const HeadingComponent = ({level, children}) => {
  switch (level) {
    case 1:
      return <Section.Header name={children} />;
    case 2:
      return (
        <Heading
          color="text"
          mt={4}
          mb={2}
          as="h2"
          fontSize={['1.20rem', '1.40rem', '1.60rem']}>
          {children}
        </Heading>
      );
    case 3:
      return (
        <Heading
          color="text"
          mt={4}
          mb={2}
          as="h3"
          fontSize={['1.10rem', '1.25rem', '1.40rem']}>
          {children}
        </Heading>
      );
    default:
      return children;
  }
};

MarkdownLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default {
  paragraph: MarkdownParagraph,
  list: MarkdownList,
  listItem: MarkdownListItem,
  link: MarkdownLink,
  heading: HeadingComponent,
};
