import Headroom from 'react-headroom';
import styled from 'styled-components';

export const HeaderContainer = styled(Headroom)`
  .headroom--pinned {
    background: ${(props) => props.theme.colors.primaryDark};
  }

  position: absolute;
  width: 100%;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.background};
  }
`;
