import React from 'react';
import styled from 'styled-components';
import {Section} from 'react-scroll-section';
import {Heading} from 'rebass/styled-components';
import PropTypes from 'prop-types';
import Slide from 'react-reveal/Slide';
import LinkAnimated from './LinkAnimated';

const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
  }
`;

const StyledHeading = styled(Heading)`
  line-height: 2;
`;

const DefaultBackground = () => <div />;

const Container = ({id, children, Background = DefaultBackground}) => (
  <Section id={id} style={{position: 'relative'}}>
    <Background />
    <SectionContainer>{children}</SectionContainer>
  </Section>
);

Container.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Background: PropTypes.func,
};

const Header = ({name}) => (
  <Slide left>
    <StyledHeading color="secondaryDark" mb={4}>
      <LinkAnimated selected>{name}</LinkAnimated>
    </StyledHeading>
  </Slide>
);

Header.propTypes = {
  name: PropTypes.any,
};

export default {
  Container,
  Header,
};
