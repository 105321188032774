import styled from 'styled-components';
import {Image, Text} from 'rebass/styled-components';

const CARD_HEIGHT = '200px';
const IMAGE_SIZE = '125px';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 0;
`;

export const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: table;
  border-bottom: ${(props) => props.theme.colors.primary} 5px solid;
`;

export const StoreLinks = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  min-height: ${CARD_HEIGHT};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${IMAGE_SIZE});
`;

export const ProjectImage = styled(Image)`
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  margin-left: 10px;
  margin-top: 0px;
  border-radius: 10px;
`;
