import React from 'react';
import {Flex} from 'rebass/styled-components';
import {SectionLinks} from 'react-scroll-section';
import Fade from 'react-reveal/Fade';
import FontAwesomeIcon from 'react-fontawesome';

import {Logo} from '../../svgs';
import {HeaderContainer} from './styled';

const Header = () => (
  <HeaderContainer>
    <Fade top>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        p={[2, 3, 4]}>
        <SectionLinks>
          {() => (
            <a onClick={() => window.history.back()}>
              <Flex flexDirection="row" alignItems="center">
                <FontAwesomeIcon name="arrow-left" />
                <Logo width="40" height="40" style={{cursor: 'pointer'}} />
              </Flex>
            </a>
          )}
        </SectionLinks>
      </Flex>
    </Fade>
  </HeaderContainer>
);

export default Header;
