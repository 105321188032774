import {OutboundLink} from 'gatsby-plugin-gtag-outbound';
import styled from 'styled-components';

export const StyledLink = styled(OutboundLink)`
  display: inline-block;
  transition: color 250ms, text-shadow 250ms;
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  position: relative;
  text-decoration: none;

  &:after {
    position: absolute;
    z-index: -1;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.theme.colors.primaryLight};
    transition: all 250ms;
  }

  &:hover {
    color: white;

    &::after {
      height: 110%;
      width: 110%;
    }
  }
`;

export const MarkdownParagraph = styled.p`
  line-height: 2em;
  color: ${(props) => props.theme.colors.text};
  &:first-child {
    margin-top: 0em;
  }
`;

export const MarkdownList = styled.ul`
  margin: 0;
  color: ${(props) => props.theme.colors.text};
`;

export const MarkdownListItem = styled.li`
  margin: 0.5em 0;
  line-height: 2em;
  color: ${(props) => props.theme.colors.text};
`;
