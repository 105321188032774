module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#E1FFFF',

  text: '#576066',

  primary: '#58ADEA',
  primaryLight: '#72C7FF',
  primaryDark: '#3F94D1',

  secondary: '#7BD3FC',
  secondaryLight: '#95EDFF',
  secondaryDark: '#62BAE3',

  android: '#AFBF5B',
  androidLight: '#C9D975',

  apple: '#A3ACB2',
  appleLight: '#BDC6CC',
};
