import {Text} from 'rebass/styled-components';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  max-width: 1366px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  @media (max-width: 450px) {
    flex-direction: column;

    & > * {
      margin-bottom: 10px;
    }
  }
`;

export const TextFooter = styled(Text)`
  color: ${(props) => props.theme.colors.background};
`;
