import React from 'react';
import PropTypes from 'prop-types';
import {Text, Box} from 'rebass/styled-components';
import {navigate} from 'gatsby';

import {Card} from '../Card';
import IconLink from '../IconLink';

import {
  HeaderContainer,
  ContentContainer,
  Title,
  TextContainer,
  ProjectImage,
  StoreLinks,
} from './styled';

const Project = ({
  name,
  logo,
  overview,
  appStoreUrl,
  playStoreUrl,
  websiteUrl,
  slug,
}) => (
  <Card p={0} onClick={() => navigate(`/projects/${slug}`)}>
    <HeaderContainer>
      <Title my={2} pb={1} color="text">
        {name}
      </Title>
      <StoreLinks>
        {appStoreUrl && (
          <Box mx={1} fontSize={4}>
            <IconLink
              name="View on the App Store"
              fontAwesomeIcon="apple"
              url={appStoreUrl}
              color="apple"
              hoverColor="appleLight"
            />
          </Box>
        )}
        {playStoreUrl && (
          <Box mx={1} fontSize={4}>
            <IconLink
              name="View on the Google Play Store"
              fontAwesomeIcon="android"
              url={playStoreUrl}
              color="android"
              hoverColor="androidLight"
            />
          </Box>
        )}
        {websiteUrl && (
          <Box mx={1} fontSize={4}>
            <IconLink
              name="View their website"
              fontAwesomeIcon="globe"
              url={websiteUrl}
            />
          </Box>
        )}
      </StoreLinks>
    </HeaderContainer>
    <ContentContainer>
      <TextContainer>
        <Text width={[1]} style={{overflow: 'auto'}} color="text">
          {overview}
        </Text>
      </TextContainer>
      <ProjectImage src={logo.image.src} alt={logo.title} />
    </ContentContainer>
  </Card>
);

Project.propTypes = {
  name: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
  appStoreUrl: PropTypes.string,
  playStoreUrl: PropTypes.string,
  websiteUrl: PropTypes.string,
  logo: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
  slug: PropTypes.string.isRequired,
};

export default Project;
