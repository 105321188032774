import React, {Fragment} from 'react';
import {Flex} from 'rebass/styled-components';
import {SectionLinks} from 'react-scroll-section';
import Fade from 'react-reveal/Fade';

import RouteLink from '../RouteLink';
import {Logo} from '../../svgs';
import {HeaderContainer} from './styled';

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

const formatLinks = (allLinks) =>
  Object.entries(allLinks).reduce(
    (acc, [key, value]) => {
      const isHome = key === 'home';
      return isHome
        ? {
            ...acc,
            home: value,
          }
        : {
            ...acc,
            links: [...acc.links, {name: capitalize(key), value}],
          };
    },
    {links: [], home: null},
  );

const HomeHeader = () => (
  <HeaderContainer>
    <Fade top>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        p={[2, 3, 4]}>
        <SectionLinks>
          {({allLinks}) => {
            const {home, links} = formatLinks(allLinks);

            const homeLink = home && (
              <Logo
                width="40"
                height="40"
                onClick={home.onClick}
                style={{cursor: 'pointer'}}
              />
            );
            const navLinks = links.map(({name, value}) => (
              <RouteLink
                key={name}
                onClick={value.onClick}
                selected={value.isSelected}
                name={name}
              />
            ));

            return (
              <Fragment>
                {homeLink}
                <Flex>{navLinks}</Flex>
              </Fragment>
            );
          }}
        </SectionLinks>
      </Flex>
    </Fade>
  </HeaderContainer>
);

export default HomeHeader;
