import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {Box, Flex} from 'rebass/styled-components';
import Fade from 'react-reveal/Fade';

import {FooterContainer, TextFooter} from './styled';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        contentfulHome {
          name
        }
      }
    `}
    render={(data) => {
      const {name} = data.contentfulHome;
      const currentYear = new Date().getFullYear();

      return (
        <Box p={[2, 3]} backgroundColor="primary" as="footer">
          <FooterContainer>
            <Fade left>
              <TextFooter style={{textAlign: 'center'}} fontSize={[2, 3]}>
                <span>{`Copyright © ${currentYear} ${name}`}</span>
              </TextFooter>
            </Fade>
            <Flex>
              <Fade right>
                <TextFooter fontSize={[2, 3]}>
                  <span>Company number: 11410544</span>
                </TextFooter>
              </Fade>
            </Flex>
          </FooterContainer>
        </Box>
      );
    }}
  />
);

export default Footer;
